import VueRouter from 'vue-router';
import Vue from 'vue';

import routes from './routes';
import store from '@/store/index';

const router = new VueRouter({
  routes,
  mode: 'history'
});

const DEFAULT_TITLE = 'subphonic';

router.afterEach(async (to) => {
  // console.log('RE-ROUTED:', 'to:',to.name, 'from:',from.name)
  store.dispatch('routeChangeHandler',to)

  Vue.nextTick(() => {
      document.title = to.meta.title ? `${DEFAULT_TITLE} | ${to.meta.title}` : DEFAULT_TITLE
  });
})

export default router;
