import Vue from 'vue'
import App from './App.vue'
import router from './router/router';
import store from './store';

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import vuescroll from 'vue-scroll'
Vue.use(vuescroll, {debounce: 200})

import VueRouter from 'vue-router'
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
