<template>
  <transition name="fade-transition" :duration="{enter: inDuration, leave: outDuration}" >
    <slot></slot>
  </transition>
</template>
<script>
export default {
  name: 'FadeTransition',
  props: {
    inDuration: { type: Number, required: false, default: 500 },
    outDuration: { type: Number, required: false, default: 500 }
  },
};
</script>

<style lang="scss">
.fade-transition {
  &-enter-active, &-leave-active {
    transition: opacity 0.4s linear;
  }

  &-enter, &-leave-to {
    opacity: 0;
  }
}
</style>
