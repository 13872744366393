<template>
  <div id="main-menu">
    <div class="pages">
      <span v-for="page in pages"
      :key="page.name"
      class="page-link"
      :class="{current: currentPageName === page.name}"
      >
        <router-link :to="{'name': page.name}">
          <b>{{page.text}}</b>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainMenu',
  data: () => ({
    pages: [
      {
        name: 'work',
        text: 'work'
      },
      {
        name: 'about',
        text: 'about me'
      },
      {
        name: 'contact',
        text: 'contact'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      'currentPageName': 'getCurrentRouteName'
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #main-menu {
    display: grid;
    justify-content: flex-start;
  }
  .pages {
    align-self: center;
    display: grid;
    grid-gap: calc(var(--page-padding)*3);
    grid-auto-flow: column;

    & .page-link {
      a {
        font-family: var(--subheading-font) !important;
        transition: all 0.2s ease;
      }
      &.current, &:hover {
        a {
          color: var(--brand-colour-lime);
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .pages {
      grid-gap: calc(var(--page-padding)*1.5);
    }
  }
</style>
