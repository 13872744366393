export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home "*/ '@/views/Home.vue'),
    meta: {
      title: 'home'
    }
  },
  {
    path: '/work',
    name: 'work',
    component: () => import(/* webpackChunkName: "SpaceSettings "*/ '@/views/Work.vue'),
    meta: {
      title: 'work'
    }
  },
  {
    path: '/about-me',
    name: 'about',
    component: () => import(/* webpackChunkName: "SpaceSettings "*/ '@/views/About.vue'),
    meta: {
      title: 'about'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "SpaceSettings "*/ '@/views/Contact.vue'),
    meta: {
      title: 'contact'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404Page "*/ '@/views/404.vue'),
    meta: {
      requiresAuth: false,
    },
  }
];
