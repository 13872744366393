<template>
  <div id="app">
    <fade-transition>
      <div class="loader" v-show="loading">
        <img src="@/assets/icons/subphonic-logo-animation.gif" alt="loading" />
      </div>
    </fade-transition>

    <fade-transition>
      <div class="body" v-show="!loading && loadedOut"
           :class="{'active-scroll': ifScrolling}"
           @scroll="handleScroll"
      >
        <div class="aside">
          <div class="header" :class="{disabled: currentPageName === 'home'}">
            <router-link :to="{name: 'home'}">
              <img class="icon" src="@/assets/icons/subphonic-logo-icon.svg" alt="home" />
            </router-link>

            <main-menu/>
          </div>
        </div>
        <div class="main" v-if="!loading && loadedOut">
          <div class="page-content" :class="currentPageName">
            <fade-transition>
              <router-view></router-view>
            </fade-transition>
          </div>

          <FooterElement v-if="currentPageName !== ''" />
        </div>
      </div>
    </fade-transition>
  </div>
</template>

<script>
import MainMenu from '@/components/menu/MainMenu.vue'
import FooterElement from '@/components/footer/Footer.vue'

import FadeTransition from '@/components/global/transitions/FadeTransition.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    MainMenu,
    FooterElement,
    FadeTransition,
  },
  data: () => ({
    loading: true,
    loadedOut: false,
    ifScrolling: false,
    scrollDebounce: null,
  }),
  computed: {
    ...mapGetters({
      'currentPageName': 'getCurrentRouteName'
    }),
  },
  methods: {
    handleScroll() {
      this.ifScrolling = true
      this.scrollDebounce = null
      clearTimeout(this.scrollDebounce)
      this.scrollDebounce = setTimeout(() => {
        this.ifScrolling = false
        this.scrollDebounce = null
      },800)
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      setTimeout(() => {
        this.loadedOut = true
      }, 500)
    },4100)
  }
}
</script>

<style lang="scss">
#app {
  width: calc(100vw - (var(--page-padding) *1.25));
  height: calc(100vh - var(--page-padding)*2);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background: var(--page-background-colour);
  color: var(--page-text-colour);
  margin: 0;
  padding: var(--page-padding);
  padding-right: calc(var(--page-padding)*0.25);
  display: grid;
}
.loader {
  margin: auto;
}
.body {
  overflow: auto;
  display: grid;
  grid-template-columns: var(--page-header-height) auto var(--page-header-height);
  margin: calc(var(--page-padding)* -0.6) 0;
  padding-top: var(--page-header-offset);

  &:not(.active-scroll)::-webkit-scrollbar-thumb {
    background: var(--page-background-colour);
  }
}

.header {
  top: 0;
  left: 0;
  height: var(--page-header-height);
  width: calc(100vw - var(--page-padding)*2 - 1rem);
  position: fixed;
  padding: var(--page-padding);
  cursor: pointer;
  transition: all 0.17s ease;
  background: var(--page-background-colour);
  z-index: 900;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: calc(var(--page-padding)*1.5);


  & .icon {
    height: var(--page-header-height);
    width: var(--page-header-height);
  }

  &:not(.disabled) {
    & .icon {
      &:hover {
        transform: scale(1.04);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  &.disabled {
    & .icon {
      &:hover {
        filter: blur(0.4px);
      }
    }
  }
}

.main {
  position: relative;
  right: calc(var(--page-padding)*0.25);
  width: calc(100% - var(--page-padding)*3.5);
  padding: calc(var(--page-padding)*0.5) var(--page-padding);
  margin: 0 auto;
  max-width: var(--page-max-content-width);
  display: grid;
  grid-auto-flow: row;

  & .page-content:not(.home) {
    margin-top: calc(var(--page-padding)*2);
    padding-bottom: calc(var(--page-padding)*15);
  }
}

@media only screen and (min-width: 600px) {
  .main {
    & .page-content {
      padding-bottom: calc(var(--page-padding)*2) !important;
    }
  }
}
</style>
