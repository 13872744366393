import Vue from "vue";
import Vuex from "vuex";
// import root from "./modules/root";

// routing ---------------------------------------------------------------------
import routing from "./modules/routing/routing";
import work from "./modules/work/work";

// // search ----------------------------------------------------------------------
// import SearchModules from "./modules/search"

// create connection ------------ //
Vue.use(Vuex);

// collection of all modules --------------//
// allowing access from vue
export default new Vuex.Store({
  modules: {
    // root,

    // routing -------------
    routing,

    // work ----------------
    work,

    // // search ----------
    // ...SearchModules,
  },
});
