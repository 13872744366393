<template>
  <div id="footer">
    <h5 v-for="detail in details"
        :key="detail.type"
    >
      <span>{{detail.type}}</span>
      <span v-if="detail.val">: </span>
      <a v-if="detail.link" :href="detail.link" target="_blank">
        <u>{{detail.val}}</u>
      </a>
      <span v-else-if="detail.val">{{detail.val}}</span>
    </h5>
  </div>
</template>

<script>
export default {
  name: 'FooterElement',
  data: () => ({
    details: [
      {
        type: 'Liam Gilheany',
      },
      {
        type: '@',
        val: 'liamgilheany97@gmail.com'
      },
      {
        type: 'tel',
        val: '+447710 877 621'
      },
      {
        type: 'cv',
        val: 'click here to view',
        link: '/Liam Gilheany CV.pdf'
      }
    ]
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #footer {
    position: fixed;
    padding: 0.5rem;
    padding-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    background: linear-gradient(rgba(0,0,0,0), var(--page-background-colour) 35%);
    bottom: 0;
    left: 0;
    width: 98vw;
    align-content: flex-end;
    grid-template-columns: auto auto;
    justify-content: flex-start;
  }

  @media only screen and (min-width: 600px) {
    #footer {
      grid-template-columns: auto auto auto;
      justify-content: space-between;
    }
  }
  @media only screen and (min-width: 992px) {
    #footer {
      grid-template-columns: auto auto auto auto;
      justify-content: space-between;
    }
  }

</style>
