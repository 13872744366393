//state data for changing and handling route changes

import router from '@/router/router.js';

const state = {
  // current route data
  currentRouteData: {},
};

const getters = {
  // get current route data (router.currentRoute object)
  getCurrentRouteData: (state) => state.currentRouteData,
  // get current route name (router.currentRoute.name)
  getCurrentRouteName: (state,getters) => {
    return getters.getCurrentRouteData?.name || ''
  },
  // get current route url
  getCurrentRouteUrl: (state,getters) => {
    return getters.getCurrentRouteData?.path || ''
  },
};

const mutations = {
  // set current route data (router.currentRoute object)
  // see router.js (src > router > router.js for where this is called)
  setCurrentRouteData(state, data) {
    state.currentRouteData = data
  },
};

const actions = {
  async clearRouteQuery() {
    router.replace({'query': null});
  },
  async routeChangeHandler({commit}, to) {
    let body = document.querySelector('.body')
    if(body) body.scrollTo(0, 0)
    commit('setCurrentRouteData', to);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
