const state = {
  projects: [
    {
      id: 'amiga',
      title: 'AMIGA',
      desc: '3D audio-reactive piece paying homage to jungle music.',
      info: '3D audio-reactive piece paying homage to jungle music. I took inspiration from the original software used to create dance music, octomed. Each screen (barring the main all encompassing ones) reacts to a different frequency range within a track. The floppy disk is a tribute to how producers in the 80’s and 90’s used to get breaks, loops and one-shot audio loaded onto their Amiga computers to produce Jungle and other dance music.',
      img: 'floppy-disk-3d.gif'
    },
    {
      id: 'grotesk',
      title: 'GROTESK',
      desc: 'Trying to bring life to a static design.',
      info: 'Trying to bring life to a static design. I created a design in Adobe Illustrator following guidelines of the Swiss-Inspired ‘Grotesk’ style. I animated these with simple effects to try and bring life to the design whilst also not overwhelming a consumer.',
      img: 'type-animation.gif'
    },
    {
      id: 'kotr',
      title: 'Hospital Records: KOTR',
      desc: 'Edit, graphics and grade.',
      info: 'Edit, graphics and grade. I approached the label weeks before the pandemic began. They asked me to create this video along with pieces for their social channels to accompany the release of The Main Event EP by Kings of the Rollers. Unfortunately this was released during the pandemic during which all independent labels understandably suffered. This piece was the catalyst for my career trajectory as it stands today.',
      img: 'KOTR.gif',
      embed: 'https://www.youtube.com/embed/mwgfo4g5VNI'
    },
    {
      id: 'joysticks',
      title: 'JOYSTICKS',
      desc: 'Collection of 3D Renders.',
      info: 'I created a collection of six 3D Joystick renders with the initial plan to test them in the NFT space. In doing this I gained near 1k followers on twitter in the space of a fortnight. These were rendered using Corona Renderer (I have an AMD GPU, meaning this is the best I can get at the moment).',
      img: 'joysticks.gif'
    },
    {
      id: 'logo-animation',
      title: 'LOGO ANIMATION',
      desc: 'Stop motion fluid motion.',
      info: 'Stop motion fluid motion. In an attempt to flex some sort of versatility - I created a 12FPS stop motion logo animation. This was drafted in Procreate before making a clean version in after effects. I first created it with basic shapes to test the motion before committing myself to a couple hours of moving keyframes.',
      img: 'subphonic-logo-animation.gif'
    },
    {
      id: 'floppy-2d',
      title: '2D Floppy',
      desc: 'A transition piece from the ‘AMIGA’ 3D video.',
      info: 'A transition piece from the ‘AMIGA’ 3D video. I used shapes in after effects and frame-by-frame shading to create a 2D cartoon animation. The rings outside the disk are representative of the entire frequency spectrum of a piece of audio.',
      img: 'floppy-disk-2d.gif'
    },
    {
      id: 'boot-screen',
      title: 'BOOT SCREEN',
      desc: 'An introduction to my showreel.',
      info: 'An introduction to my showreel. This was my attempt at creating an old school style video game screen.',
      img: 'reel-intro.gif'
    },
    {
      id: 'oscillate',
      title: 'OSCILLATE',
      desc: 'Kinetic typography with audio-reactive nodes.',
      info: 'Kinetic typography with audio-reactive nodes. Each node reacts to a different instrument within my showreel, using saw and sine waves to represent audio. The text transition used was also fashioned using a sine wave.',
      img: 'oscillate-animation.gif'
    },
  ],

  // popup
  currentProjectPopup: null
};

const getters = {
  getProjects: (state) => state.projects,

  // get current project popup (when clicking on a project to see more info)
  getCurrentProjectPopup: (state) => state.currentProjectPopup,
};

const mutations = {
  setCurrentProjectPopup(state, data) {
    state.currentProjectPopup = data
  },
};

const actions = {
  async closeProjectPopup({commit}) {
    commit('setCurrentProjectPopup', null)
  },
  async openProjectPopup({commit}, data) {
    commit('setCurrentProjectPopup', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
